import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
// import { ethers } from "ethers";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import "./stakinginner.css";
// import Web3 from "web3";
import { callAPI, getCallAPIWithToken } from "../../services/AuthServices";

const Stakinginner = () => {
  const [show, setShow] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(false);
  const [loaderForConnect, setLoaderForConnect] = useState(false);
  const [defaultAccount, setDefaultAccount] = useState(null);
  // const [userBalance, setUserBalance] = useState(null);
  const [connButtonText, setConnButtonText] = useState("Connect Wallet");
  const [packages, setPackages] = useState(null);
  const [packageValue,setPackageValue] = useState(0);

  const handleClose = () => setShow(false);
  const handleClose3 = () => setLoaderForConnect(true);
  const handleShow = () => setShow(false);
  const closePopup = () => setLoader(false);
  const closePopup3 = () => setLoaderForConnect(false);

  const connectWalletHandler = () => {
    if (window.ethereum) {
      window.ethereum
        .request({
          method: "eth_requestAccounts",
        })
        .then((result) => {
          accountChangeHandler(result[0]);
        });
    } else {
      console.log("Install MetaMask");
    }
  };

  const accountChangeHandler = (newAccount) => {
    login(newAccount);
  };

  // const getUserBalance = (address) => {
  //   window.ethereum
  //     .request({
  //       method: "eth_getBalance",
  //       params: [address, "latest"],
  //     })
  //     .then((balance) => {
  //       setUserBalance(ethers.utils.formatEther(balance));
  //     });
  // };

  const handleDisconnect = () => {
    setDefaultAccount("");
    // setUserBalance(0);
    window.location.reload();
  };

  if (typeof window.ethereum !== "undefined") {
    window.ethereum.on("accountsChanged", accountChangeHandler);
  }

  function timeFormater(date, separator) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    return hours + separator + minutes + separator + seconds;
  }

  const login = async (address) => {
    const data = {
      address: address,
    };
    const response = await callAPI(data, "auth/signin");
    if (response.statusCode === 200) {
      localStorage.setItem("randomToken", response.data.token);
      setDefaultAccount(address);
      setConnButtonText("Connect Wallet");
      setLoader(true);
      getPackages();
      
    } else {
      // setLoader(true);
      setLoaderForConnect(true);
      // alert('Address not register with us please check and try again later..')
    }
  };

  const getPackages = async () => {
    const response = await getCallAPIWithToken("packagesList?skip=0&limit=10");
    setPackages(response?.packages);
  };

  const showData = (e, id,minPrice) => {
    e.preventDefault();
    let paragraphs = document.getElementsByClassName("thisIsMyClass");

    // Loop through each paragraph element
    for (let i = 0; i < paragraphs.length; i++) {
      let paragraph = paragraphs[i];

      // Add a class to each paragraph
      paragraph.classList.add("firstRowHide");
    }

    let element = document.getElementById(`removeClass` + id);
    element.classList.remove(`firstRowHide`);
    setPackageValue(minPrice);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (typeof window.ethereum === "undefined") {
      setError("done");
      setTimeout(() => {
        handleShow();
      }, 200);
      console.log("Please install MetaMask");
    }
  }, []);

  // const date = new Date();

// const day = date.getDate();
// const month = date.getMonth() + 1;
// const year = date.getFullYear();
// const minute = date.getMinutes();
// const second = date.getSeconds();
// const miliseconds = date.getMilliseconds();

let currentDate = new Date().toJSON().slice(0, 10);

  return (
    <div>
      <Header />
      <section className="staking-inner">
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <div className="inner-heading">
                <h2 className="heading mb-0">Stake your InrCoin</h2>
                <h1 className="sub-heading mb-0">Earn InrCoin</h1>
                <p>
                  Stake your InrCoin and grow back while supporting our
                  ecosystem
                </p>
                {defaultAccount && (
                  <span onClick={handleDisconnect}>
                    <div className="stakin-inner-heading-box">
                      <h6>{defaultAccount}</h6>
                    </div>
                  </span>
                )}
                <div className="stakin-inner-heading-btn">
                  {defaultAccount === null && (
                    <button onClick={connectWalletHandler}>
                      {connButtonText}
                    </button>
                  )}
                </div>
              </div>
            </div>
            {defaultAccount && (
              <>
                <div className="col-12">
                  <div className="staking-inner-second">
                    <div className="staking-inner-second-box">
                      <h4>
                        $INRC
                        <img src="assets/images/stakin-inner1.svg" alt="icon" />
                        {0}
                      </h4>
                      <p>Total Staked</p>
                    </div>
                    <div className="staking-inner-second-box staking-inner-second-box1">
                      <h4>$ {0}</h4>
                      <p>Total Value Locked</p>
                    </div>
                    <div className="staking-inner-second-box">
                      <h4>$ {0}</h4>
                      <p>Price InrCoin</p>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center">
                  <div className="inner-staking-locked">
                    <ul
                      className="nav nav-pills mb-0 justify-content-center"
                      id="pills-tab"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-home-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-home"
                          type="button"
                          role="tab"
                          aria-controls="pills-home"
                          aria-selected="true"
                        >
                          STAKING
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-profile-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#pills-profile"
                          type="button"
                          role="tab"
                          aria-controls="pills-profile"
                          aria-selected="false"
                        >
                          MY LOCKED STAKING
                        </button>
                      </li>
                    </ul>
                    <div className="inner-staking-locked-box">
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div className="table-outer">
                            <table className="table table-dark ">
                              <thead>
                                <tr>
                                  <th scope="col">Package</th>
                                  <th scope="col">APR</th>
                                  <th scope="col">Duration</th>
                                  <th scope="col">Type</th>
                                  <th scope="col"> </th>
                                </tr>
                              </thead>
                              <tbody>
                                {packages &&
                                  packages.length > 0 &&
                                  packages.map((item, key) => (
                                    <React.Fragment key={key}>
                                      <tr className="dark-tr">
                                        <th>{item?.title}</th>
                                        <td>
                                          <span className="apy-box">
                                            {item?.commission}%
                                            <span className="apy-box-plush">
                                              +
                                            </span>
                                            <span className="apy-box-text">
                                              1% Royalty
                                            </span>
                                          </span>
                                        </td>
                                        <td>
                                          <span className="lock">{item?.time} {item?.timeType.toUpperCase()}</span>
                                        </td>
                                        <td>
                                          <span className="lock">Lock</span>
                                        </td>

                                        <td>
                                          <button
                                            onClick={(e) => showData(e, key,item?.minPrice)}
                                            className="stake"
                                          >
                                            Stake
                                          </button>
                                        </td>
                                      </tr>

                                      <tr
                                        className={`dark-tr thisIsMyClass firstRowHide ${
                                          "removeClass" + key
                                        }`}
                                        id={`${"removeClass" + key}`}
                                      >
                                        <td colSpan="6">
                                          <div className="staking-detail">
                                            <div className="staking-detail-bar-outer">
                                              <div className="staking-detail-bar">
                                                <div className="staking-bar-text">
                                                  <p>Total Staked</p>
                                                  <h4>{0}</h4>
                                                </div>
                                              </div>
                                              <div className="staking-bar">
                                                <p>Pool Limit</p>
                                                <div className="staking-bar-number">
                                                  <h5>Unlimited</h5>
                                                </div>
                                                <div className="progress">
                                                  <div
                                                    className="progress-bar"
                                                    role="progressbar"
                                                    aria-valuenow="25"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                  ></div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="stack-mpc">
                                              <div className="stack-mpc-heading">
                                                <h4>Stake</h4>
                                                <div className="stack-mpcheading-right">
                                                  <Link
                                                    to={"register"}
                                                    rel="noopener noreferrer"
                                                  >
                                                    <h3>
                                                      Buy INRC{" "}
                                                      <img
                                                        src="assets/images/mpc1.png"
                                                        alt="icon"
                                                      />
                                                    </h3>
                                                  </Link>
                                                </div>
                                              </div>
                                              <div className="mpc-coin">
                                                <input
                                                  type="number"
                                                  min={item?.minPrice}
                                                  max={item?.price}
                                                  value={packageValue}
                                                  onChange={(e) => setPackageValue(e.target.value)}
                                                />
                                                <div className="mpc-coin-right">
                                                  <p>USDT</p>
                                                  <button>MAX</button>
                                                </div>
                                              </div>
                                              <span
                                                style={{ color: "red" }}
                                                className="badge badge-danger"
                                              >
                                                {error}
                                              </span>
                                            </div>
                                            <div className="staking-inner-summary">
                                              <h4>Summary</h4>
                                              <div className="summry-row">
                                                <div className="summry-row-left">
                                                  <p>Stake Date</p>
                                                </div>
                                                <div className="summry-row-right">
                                                  <p>
                                                    <img
                                                      src="assets/images/calendar.png"
                                                      alt=""
                                                    />
                                                    <span>{currentDate}</span>{" "}
                                                  </p>
                                                  <p className="border-right">
                                                    <img
                                                      src="assets/images/clock.png"
                                                      alt=""
                                                    />
                                                    <span>
                                                      {timeFormater(
                                                        new Date(),
                                                        ":"
                                                      )}
                                                    </span>{" "}
                                                  </p>
                                                </div>
                                              </div>
                                              <div className="summry-row">
                                                <div className="summry-row-left">
                                                  <p>Interest End Date</p>
                                                </div>
                                                <div className="summry-row-right">
                                                  <p>
                                                    <img
                                                      src="assets/images/calendar.png"
                                                      alt=""
                                                    />
                                                    <span>{"Final Day"}</span>
                                                  </p>
                                                  <p className="border-right">
                                                    <img
                                                      src="assets/images/clock.png"
                                                      alt=""
                                                    />
                                                    <span>
                                                      {timeFormater(
                                                        new Date(),
                                                        ":"
                                                      )}
                                                    </span>{" "}
                                                  </p>
                                                </div>
                                              </div>
                                              {/* <div className="summry-row">
                                                <div className="summry-row-left">
                                                  <p>Withdrawal Delay Time</p>
                                                </div>
                                                <div className="summry-row-right">
                                                  <p>
                                                    <span>None</span>
                                                  </p>
                                                </div>
                                              </div> */}
                                              <div className="summry-row">
                                                <div className="summry-row-left">
                                                  <h6>APR</h6>
                                                </div>
                                                <div className="summry-row-right">
                                                  <h5>{item?.commission}% + 1% Royalty</h5>
                                                </div>
                                              </div>
                                              <div className="stakin-inner-heading-btn text-center">
                                                <button>Confirm Staking</button>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </React.Fragment>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div
                          className="tab-pane fade"
                          id="pills-profile"
                          role="tabpanel"
                          aria-labelledby="pills-profile-tab"
                        >
                          <div className="table-outer2">
                            <table className="table table-dark ">
                              <thead>
                                <tr>
                                  <th scope="col">Package</th>
                                  <th scope="col">Total Amount</th>
                                  <th scope="col">APR</th>
                                  <th scope="col">Stake Date</th>
                                  <th scope="col">Locked Days</th>
                                  <th scope="col">Interest End Date</th>
                                  <th scope="col">Accrue Days </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr className="not-connect-outer">
                                  <td colSpan="8">
                                    <div className="not-connect">
                                      <img
                                        src="assets/images/MetaMask_Fox1.png"
                                        alt=""
                                      />
                                      <h5>
                                        Please connect to your Metamask wallet
                                      </h5>
                                    </div>
                                  </td>
                                </tr>
                                {[0, 1, 2].map((item, key) => (
                                  <React.Fragment key={key}>
                                    <tr
                                      className={
                                        key % 2 === 0 ? "dark-tr" : "light-tr"
                                      }
                                    >
                                      <td>{item.package}</td>
                                      <td>$INRC {item.totalAmount}</td>
                                      <td>{item.lockedDay} Days</td>
                                      <td>{item.stackDate}</td>
                                      <td>{item.lockedDay} Days</td>
                                      <td>{item.endDate}</td>
                                      <td>1</td>
                                    </tr>
                                  </React.Fragment>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="inner-staking-rpund">
          <img src="assets/images/inner-staking'.png" alt="" />
        </div>
      </section>
      <div className="staking-popup1">
        <Modal
          className="staking-popup1"
          show={show}
          onHide={handleClose}
          backdrop="static"
        >
          <Modal.Body>
            {" "}
            <div>
              <div className="close popup-close text-center">
                <img src="assets/images/popup-close.png" alt="" />
              </div>
              <img src="assets/images/popup1.png" alt="" />
              <h5>InrCoin Staking</h5>
              <p>MetaMask is not installed</p>
            </div>{" "}
          </Modal.Body>
        </Modal>

        <div
          className="modal fade"
          id="exampleModal2"
          tabIndex="-1"
          aria-labelledby="exampleModal1Label"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div
                  className="popup-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="assets/images/popup-close.png" alt="" />
                </div>
                <img src="assets/images/popup1.png" alt="" />
                <h5>InrCoin Staking</h5>
                <p>MetaMask is not installed</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="staking-popup1">
        <Modal
          className="staking-popup1"
          show={loader}
          onHide={handleClose}
          backdrop="static"
        >
          <Modal.Body>
            {" "}
            <div>
              <div
                className="close popup-close text-center"
                onClick={closePopup}
              >
                <img src="assets/images/popup-close.png" alt="" />
              </div>
              <img src="assets/images/popup2.png" alt="" />
              <h5>InrCoin Staking</h5>
              <p>Connected Successfully</p>
            </div>{" "}
          </Modal.Body>
        </Modal>
      </div>

      <div className="staking-popup1">
        <Modal
          className="staking-popup1"
          show={loaderForConnect}
          onHide={handleClose3}
          backdrop="static"
        >
          <Modal.Body>
            {" "}
            <div>
              <div
                className="close popup-close text-center"
                onClick={closePopup3}
              >
                <img src="assets/images/popup-close.png" alt="" />
              </div>
              <img src="assets/images/popup1.png" alt="" />
              <h5>InrCoin Level/Staking</h5>
              <p>
                Your address not register with us please check and try again
                later..
              </p>
              <Link
                className="btn btn-info"
                to={"register"}
                rel="noopener noreferrer"
              >
                Click to Register
              </Link>
            </div>{" "}
          </Modal.Body>
        </Modal>
      </div>

      <div className="staking-popup1">
        <div
          className="modal fade"
          id="exampleModal4"
          tabIndex="-1"
          aria-labelledby="exampleModal1Label"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div
                  className="popup-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="assets/images/popup-close.png" alt="" />
                </div>
                <img src="assets/images/popup2.png" alt="" />
                <h5>InrCoin Staking</h5>
                <p>Logout Successfully</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="staking-popup1 staking-popup1">
        <div
          className="modal fade"
          id="exampleModal5"
          tabIndex="-1"
          aria-labelledby="exampleModal1Label"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div
                  className="popup-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <img src="assets/images/popup-close.png" alt="" />
                </div>

                <p>0x8DB14e19d8c235b9556362008b9BE6D02Ca33137</p>
                <div className="stakin-inner-heading-btn">
                  <button>Logout</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Stakinginner;
