import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Header from "../../components/header/Header";
import "./register.css";
import { Form, Modal } from "react-bootstrap";
import { callAPI } from "../../services/AuthServices";

const Register = () => {
  const history = useHistory();
  const [connButtonText, setConnButtonText] = useState("Connect Wallet");
  const [defaultAccount, setDefaultAccount] = useState(null);
  const [referral, setReferral] = useState("");
  const [show, setShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const handleClose = () => setShow(false);
  const closePopup = () => setLoader(false);

  const connectWalletHandler = () => {
    if (window.ethereum) {
      window.ethereum
        .request({
          method: "eth_requestAccounts",
        })
        .then((result) => {
          accountChangeHandler(result[0]);
          setLoader(true);
        });
    } else {
      console.log("Install MetaMask");
    }
  };

  const register = async () => {
    try {
      const data = {
        sponsorId: referral,
        address: defaultAccount,
      };
      const response = await callAPI(data, "auth/signup");
      if (response.statusCode === 200) {
        localStorage.setItem("randomToken", response.data.token);
        history.push("staking");
      } else {
        alert(response.statusMessage);
      }
    } catch (error) {
      console.error("Error during registration:", error);
      alert("An error occurred during registration.");
    }
  };
  const accountChangeHandler = (newAccount) => {
    console.log("Adress ", newAccount);
    setDefaultAccount(newAccount);
    setConnButtonText("Connect Wallet");
    console.log('show',show)
  };

  return (
    <div>
      <Header />
      <section className="section-padding staking">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="staking-heading">
                <h1 className="heading mb-0 english-lng">
                  Register Your Address
                </h1>
                <p className="heading-text english-lng">
                  Stake your INR Coin and grow your back while supporting our
                  ecosystem
                </p>
              </div>
              <div className="staking-content">
                <div className="stakin-inner-heading-btn">
                  {defaultAccount === null && (
                    <button onClick={connectWalletHandler}>
                      {connButtonText}
                    </button>
                  )}
                </div>
              </div>
              {defaultAccount && (
                <div className="stalking-heading">
                  <Form onSubmit={(e) => e.preventDefault()}>
                    <Form.Group
                      className="mb-3 formOuter"
                      controlId="exampleForm.ControlInput1"
                    >
                      <Form.Label className="label">Address</Form.Label>
                      <div className="mpc-coin">
                        <Form.Control
                          type="text"
                          placeholder="Detecting your address"
                          name="address"
                          readOnly
                          value={defaultAccount}
                        />
                      </div>
                      <Form.Label className="label">Referral</Form.Label>
                      <div className="mpc-coin">
                        <Form.Control
                          type="text"
                          placeholder="Enter Referral Address"
                          onChange={(e) => setReferral(e.target.value)}
                          name="referral"
                          value={referral}
                        />
                      </div>
                      <div className="stakin-inner-heading-btn">
                        <button type="submit" onClick={register}>
                          Register
                        </button>
                      </div>
                    </Form.Group>
                  </Form>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="stakin-circul">
          <img src="assets/images/staking-circul.png" alt="" />
        </div>
        <div className="about-line">
          <img src="assets/images/about-line.png" alt="" />
        </div>
      </section>
      <div className="staking-popup1">
        <Modal
          className="staking-popup1"
          show={loader}
          onHide={handleClose}
          backdrop="static"
        >
          <Modal.Body>
            {" "}
            <div>
              <div
                className="close popup-close text-center"
                onClick={closePopup}
              >
                <img src="assets/images/popup-close.png" alt="" />
              </div>
              <img src="assets/images/popup2.png" alt="" />
              <h5>InrCoin Level/Staking</h5>
              <p>Connected Successfully</p>
            </div>{" "}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default Register;
