import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const options1 = {
  margin: 30,
  responsiveClass: true,
  nav: false,
  dots: false,
  autoplay: true,
  center: true,
  loop: true,
  navText: false,
  smartSpeed: 1000,
  responsive: {
    0: {
      items: 2,
      margin: 20,
    },
    576: {
      items: 2,
      margin: 20,
    },
    767: {
      items: 3,
      margin: 20,
    },
    991: {
      items: 3,
    },
    1200: {
      items: 5,
    },
  },
};

export default class Carousal extends React.Component {
  render() {
    return (
      <OwlCarousel className="owl-theme" nav {...options1}>
        <div>
          <div className="team-box mt-0">
            <img src="assets/images/team-img-box.png" alt="" />
            <div className="team-content">
              <img src="assets/images/HERO.png" alt="" />
              <h4>HERO</h4>
              <p>MOD</p>
            </div>
          </div>
        </div>
        <div>
          <div className="team-box mt-0">
            <img src="assets/images/team-img-box.png" alt="" />
            <div className="team-content">
              <img src="assets/images/LUCKY.png" alt="" />
              <h4>LUCKY</h4>
              <p>MOD Team</p>
            </div>
          </div>
        </div>
        <div>
          <div className="team-box mt-0">
            <img src="assets/images/team-img-box.png" alt="" />
            <div className="team-content">
              <img src="assets/images/PEABODY.png" alt="" />
              <h4>Peabody</h4>
              <p>CFO </p>
            </div>
          </div>
        </div>
        <div>
          <div className="team-box mt-0">
            <img src="assets/images/team-img-box.png" alt="" />
            <div className="team-content">
              <img src="assets/images/RAY.png" alt="" />
              <h4>Ray</h4>
              <p>CFO </p>
            </div>
          </div>
        </div>
        <div>
          <div className="team-box mt-0">
            <img src="assets/images/team-img-box.png" alt="" />
            <div className="team-content">
              <img src="assets/images/SEMI.png" alt="" />
              <h4>Semi</h4>
              <p>Discord Manager </p>
            </div>
          </div>
        </div>
        <div>
          <div className="team-box mt-0">
            <img src="assets/images/team-img-box.png" alt="" />
            <div className="team-content">
              <img src="assets/images/EDDIE.png" alt="" />
              <h4>Eddie</h4>
              <p>MOD Team </p>
            </div>
          </div>
        </div>
      </OwlCarousel>
    );
  }
}
