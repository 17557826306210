import { baseURL } from "../env.js";

export const callAPI = async (data,endPoint) => {
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    const raw = JSON.stringify(data);
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    const result = await fetch(`${baseURL+endPoint}`, requestOptions);
    return await result.json()
}



export const getCallAPIWithToken = async (endPoint) => {
  const token = localStorage.getItem("randomToken");
  if (!token) return;
  const myHeaders = new Headers();
myHeaders.append("Authorization", `Bearer ${token}`);

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

const result = await fetch(`${baseURL+endPoint}`, requestOptions);
return await result.json()
} 