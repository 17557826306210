import React, { useEffect } from "react";
import Header from "../../components/header/Header";
import "./team.css";

const Team = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Header />
      <section className="section-padding team">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="team-inner">
                <div className="team-inner-heading">
                  <h2 className="heading mb-0 english-lng">INR Coin</h2>
                  <h1 className="sub-heading mb-0 english-lng">Team</h1>
                  <p className="heading-text english-lng">
                    A project goes farther with helping hands.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="team-outer">
            <div className="row justify-content-center">
              <div className="col-xxl-3 col-lg-4 col-6">
                <div className="team-box">
                  <img
                    className="team-black-box"
                    src="assets/images/team-img-box.png"
                    alt=""
                  />
                  <img
                    className="team-white-box"
                    src="assets/images/team-white-bg.png"
                    alt=""
                  />
                  <div className="team-content">
                    <img src="assets/images/HERO.png" alt="" />
                    <h4>HERO</h4>
                    <p>MOD</p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-lg-4 col-6">
                <div className="team-box">
                  <img
                    className="team-black-box"
                    src="assets/images/team-img-box.png"
                    alt=""
                  />
                  <img
                    className="team-white-box"
                    src="assets/images/team-white-bg.png"
                    alt=""
                  />
                  <div className="team-content">
                    <img src="assets/images/LUCKY.png" alt="" />
                    <h4>LUCKY</h4>
                    <p>CMO </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-lg-4 col-6">
                <div className="team-box">
                  <img
                    className="team-black-box"
                    src="assets/images/team-img-box.png"
                    alt=""
                  />
                  <img
                    className="team-white-box"
                    src="assets/images/team-white-bg.png"
                    alt=""
                  />
                  <div className="team-content">
                    <img src="assets/images/PEABODY.png" alt="" />
                    <h4>Peabody</h4>
                    <p>CFO </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-lg-4 col-6">
                <div className="team-box">
                  <img
                    className="team-black-box"
                    src="assets/images/team-img-box.png"
                    alt=""
                  />
                  <img
                    className="team-white-box"
                    src="assets/images/team-white-bg.png"
                    alt=""
                  />
                  <div className="team-content">
                    <img src="assets/images/RAY.png" alt="" />
                    <h4>Ray</h4>
                    <p>CFO </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-lg-4 col-6">
                <div className="team-box">
                  <img
                    className="team-black-box"
                    src="assets/images/team-img-box.png"
                    alt=""
                  />
                  <img
                    className="team-white-box"
                    src="assets/images/team-white-bg.png"
                    alt=""
                  />
                  <div className="team-content">
                    <img src="assets/images/SEMI.png" alt="" />
                    <h4>Semi</h4>
                    <p>Discord Manager </p>
                  </div>
                </div>
              </div>
              <div className="col-xxl-3 col-lg-4 col-6">
                <div className="team-box">
                  <img
                    className="team-black-box"
                    src="assets/images/team-img-box.png"
                    alt=""
                  />
                  <img
                    className="team-white-box"
                    src="assets/images/team-white-bg.png"
                    alt=""
                  />
                  <div className="team-content">
                    <img src="assets/images/EDDIE.png" alt="" />
                    <h4>Eddie</h4>
                    <p>MOD Team </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="team-round-circul">
              <img src="assets/images/team-round.png" alt="" />
            </div>
          </div>
        </div>
        <div className="team-round-img">
          <img src="assets/images/network-round.png" alt="" />
        </div>
        <div className="about-line">
          <img src="assets/images/about-line.png" alt="" />
        </div>
      </section>
    </div>
  );
};

export default Team;
